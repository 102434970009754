* {
  box-sizing: border-box;
}

#big-div {
  background-color: #f7f9fd;
  height: fit-content;
  padding-bottom: 2%;
  padding-top: 2%;
}

#head-div {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f7f9fd;
}

#orangeline {
  height: 1px;
  width: 100px;
  background-color: orangered;
}

h5 {
  color: #aaaaaa;
}

h2 {
  color: #102f57;
}

.testimonials-section {
  padding: 20px;
  width: 100%;
}

.testimonials-section img {
  height: auto;
  width: 80px;
}

.person-type {
  color: black;
}

.review {
  line-height: 1.5;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  padding: 20px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s ease;
  height: 300px;
  width: 300px;
  background-color: white;
  margin: 20px;
}

.review .icon-image {
  position: absolute;
  top: -0px;
  right: 82%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.testimonial-card-stars {
  color: #ed502e;
  font-size: 22px;
  margin-top: 20px;
}
@media screen and (max-width: 1200px) {
  .review {
    width: 45%;
    margin: 20px;
  }
}

@media screen and (max-width: 900px) {
  .review {
    width: 100%;
    margin: 10px 0;
  }

  .review1 {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  #big-div {
    padding: 2% 5%;
  }

  .testimonials-section {
    flex-direction: column;
    align-items: center;
  }

  .review {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
  }

  .icon-image {
    top: -10px;
    left: 80%;
    transform: translateX(-50%);
  }
}

.review-section {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.review-section.visible {
  opacity: 1;
}
