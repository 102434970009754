* {
    transition: .3s ease;
}

.gradient {
    background: linear-gradient(to left, #FF4500, #FFA500,  #FF4500);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.BookingBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

/* UPPER BODY */

.title {
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 1px;
    line-height: 60px;
}

#liveShow {
    border: 1px lightgray solid;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    width: 90%;
    height: 13vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#liveShow p {
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 100;
}

#liveShow a {
    font-weight: 600;
    text-decoration: none;
    color: rgb(132, 132, 247);
}

#liveShow a:hover {
    color: orangered;
}

@media screen and (max-width: 800px) {
    .title {
        font-weight: bold;
        font-size: 38px;
        letter-spacing: -0.02em;
        line-height: 60px;
    }

    #box2 p {
        font-size: 12px !important;
    }

    #line {
        width: 80px !important;
    }

    #box1 {
        width: 90% !important;
        margin: 15px 0px;
    }

}

@media screen and (max-width: 600px) {
    .title {
        font-size: 28px;
        line-height: 45px;
    }

    #box2 p {
        font-size: 11px;
    }

    #box1 {
        width: 95% !important;
        margin: 20px 0px 0px 10px !important;
    }

    #liveShow {
        width: 100%;
        height: 12vh;
        padding: 0px 25px;
    }

    #liveShow p {
        letter-spacing: .5px;
        font-size: 13px;
    }
}

/*  MAIN BODY  */

.pastEvents {
    /* border: 2px green solid; */
    width: 85%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.event {
    /* border: 2px black solid; */
    width: 40%;
    height: 48vh;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    margin-top: 20px;
}

#e1 {
    /* border: 2px yellow solid; */
    height: 70%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;

}

#e2 {
    /* border: 2px darkmagenta solid; */
    height: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#e3 {
    width: 25%;
    /* border: 2px blue solid; */
    height: 100%;
    font-size: 20px;
    color: orangered;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#partition {
    height: 80%;
    border-width: 1px;
    background-color: grey;
}

#e4 {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 2px red solid; */
    padding: 10px;
}

#e4name {
    font-size: 14px;
}

#e4place {
    font-size: 13px;
    font-weight: 100;
    margin-top: 5px;
}

@media screen and (max-width: 1067px) {
    .event {
        width: 350px;
        margin-top: 25px;
    }

}

@media screen and (max-width: 850px) {
    .event {
        width: 350px;
        margin-top: 25px;
        background-size: 90%;
    }

    #e1 {
        height: 60%;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .pastEvents {
        width: 95%;
        justify-content: space-between;
        padding: 0px 0px;
    }
}

@media screen and (max-width: 850px) {
    .event {
        width: 350px;
        margin-top: 25px;
    }

    #e1 {
        height: 60%;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .pastEvents {
        width: 95%;
        justify-content: space-between;
        padding: 0px 0px;
    }
}

@media screen and (max-width: 750px) {
    .event {
        width: 48%;
        margin-top: 25px;
        height: 40vh;
    }

    #e1 {
        height: 60%;
        background-position: center;
        background-size: 96%;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .pastEvents {
        width: 95%;
        justify-content: space-between;
        padding: 0px 0px;
    }
}

@media screen and (max-width: 600px) {
    .event {
        width: 94%;
        margin-top: 0px;
        height: 40vh;
    }

    #e1 {
        height: 70%;
        background-position: center;
        background-size: 96%;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .pastEvents {
        width: 95%;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
    }
}