/* Slideshow container */
.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  margin-top: 12px;
}

/* Slides */
.mySlides {
  height: 70vh; /* Set a fixed height for the slides */
  padding: 5% 2%;
  margin-top: 5px;
  border: 1px solid;
  background-position: center;
  background-size: cover;
  position: relative; /* Added position relative for inner text */
  overflow: hidden;
}
.mySlides::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit; /* Use the same background */
  filter: blur(3px); /* Apply blur effect */
  z-index: 0; /* Position behind the content */
}
#myslides1 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../Assets/slide/banner1.avif") center / cover no-repeat;
}

#myslides2 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../Assets/slide/img1.jpg") center / cover no-repeat;
}

#myslides3 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../Assets/slide/img3.png") center / cover no-repeat;
}

.mySlidesText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  width: 95%;
  margin-top: 3%;
  margin-left: 5%;
  position: relative;
}

.mySlidesText h1 {
  font-size: 50px;
  line-height: 60px;
}

.mySlidesText p {
  font-size: 18px;
  font-weight: 100;
  line-height: 25px;
  margin: 20px 0px;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s ease;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.next {
  right: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: lightgray;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.1s ease;
}

.active,
.dot:hover {
  background-color: orangered;
}

/* Fading animation */
.fade1 {
  animation-name: fade1;
  animation-duration: 4s;
}

.fade2 {
  animation-name: fade2;
  animation-duration: 4s;
}
.fade3 {
  animation-name: fade3;
  animation-duration: 5s;
}
@keyframes fade1 {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade2 {
  from {
    opacity: 0.2;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 600px) {
  .mySlides {
    height: 50vh;
  }
  .mySlidesText {
    margin-top: 10%;
    margin-left: 3%;
  }

  .mySlidesText h1 {
    font-size: 6vw; /* Adjusted for smaller screens */
    line-height: 7vw; /* Adjusted for smaller screens */
  }

  .mySlidesText p {
    font-size: 3vw; /* Adjusted for smaller screens */
    font-weight: 100;
    line-height: 4vw; /* Adjusted for smaller screens */
    margin: 25px 0px;
  }
}

@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
