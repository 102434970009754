@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

* {
    font-family: "Poppins";
}

.gradient {
    background: linear-gradient(to left, #FF4500, #FFA500,  #FF4500);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

/* title */

.creativity .title {
    font-weight: bold;
    font-size: 50px;
    letter-spacing: -0.02em;
    line-height: 60px;
}

.creativity {
    background-color: #FEFEFE;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    /* background: var(--bg-2); */
    transition: all 0.2s linear;
    transition: all 0.2s linear 0s;
    width: 75%;
    height: 530px;
    padding: 70px 30px 250px 60px;
    margin: 20px 0;
    margin-bottom: 10px;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    position: relative;
}

.creativity .desc {
    padding-right: 15vw;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
}

.card {
    position: absolute;
    width: 35%;
    text-align: center;
    background: #fefefe;
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
    z-index: 80;
    filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.25));
    /* box-shadow: purple 0px 3px 8px; */
    height: 200px;
}

.thanks {
    bottom: -140px;
}

.CreatorStore {
    bottom: -140px;
    left: 45%;
}

.nftWithPerks {
    right: -270px;
    top: 5%;
}

.membership {
    right: -270px;
    top: 60%;
}

.card h3 {
    margin-top: 50px;
    font-weight: bold;
    font-size: 30px;
}

.card p {
    color: #23262f;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.cardInside {
    position: relative;
}

.imgDiv {
    background: #fefefe;
    position: absolute;
    top: -105px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 4;
    padding: 0px;
    border-radius: 50%;
}

.icons {
    font-size: 50px;
}

@media screen and (max-width: 991px) {

    .desktopcards {
        display: grid;
        grid-template-columns: 48% 48%;
        align-items: center;
        justify-content: space-between;
        row-gap: 10%;
        /* border: 2px green solid; */
        margin-top: 8%;
    }

    .imgDiv {
        top: -90px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .card {
        background: #fefefe;
        height: 200px;
        margin-top: 20px;
    }

    .card h3 {
        margin-top: 40px;
        font-size: 30px;
    }

    .creativity {
        width: auto;
        padding: 25px 10px 20px 20px;
        padding-bottom: 12vh;
        height: fit-content;
        margin: 15px;
    }

    .creativity .title {
        font-weight: bold;
        font-size: 40px;
        line-height: 35px;
        letter-spacing: -0.02em;

    }

    .creativity .desc {
        padding: 0;
        font-size: 16.5px;
        line-height: 30px;
    }

    .card {
        position: unset;
        width: auto;
    }

    .youtubeBox {
        margin: 10% 0% 7% 0% !important;
    }

    .youtubeLink {
        width: 90%;
        height: 400px;
    }
}

@media screen and (max-width: 700px) {
    .desktopcards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* border: 2px green solid; */
        margin-top: 8%;
        height: fit-content;
    }

    .imgDiv {
        top: 0%;
        left: -35%;
        transform: translate(-10%, 10%);
    }

    .card {
        background: #fefefe;
        height: 200px;
        margin-top: 8%;
        width: 80%;
        padding-left: 40px;
        /* border: 2px solid red; */
    }

    .icons {
        font-size: 37px;
    }
}

@media screen and (max-width: 600px) {
    .youtubeBox {
        margin: 10% 0% 7% 0% !important;
    }

    .youtubeLink {
        width: 90% !important;
        height: 300px !important;
    }
}