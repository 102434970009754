#subsection {
  padding: 4% 5%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0b2341;
}

.big-text {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1%;
}

.normal-text {
  font-size: 1rem;
  margin-bottom: 1.5%;
}

.buttonstyle {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  padding: 1.5% 3%;
  border-radius: 40px;
  border: none;
  background-image: linear-Gradient(to right, #af05d0, #c500bd, #d500ab, #df009a, #e6118b, #f2287c, #fa3d6e, #ff5262, #ff6f51, #ff8d41, #ffaa37, #fcc737)
}

@media screen and (max-width: 860px) {
  #subsection {
    padding: 8% 5%;
  }

  .normal-text {
    padding: 2% 10%;
  }

}

@media screen and (max-width: 450px) {
  #subsection {
    padding: 10% 6%;
  }

  .big-text {
    font-size: 1.6rem;
  }

  .normal-text {
    padding: 3% 10%;
  }

  .buttonstyle {
    padding: 3% 6%;
  }
}